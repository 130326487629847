
import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import { makeParams } from '/src/utils/client-side-data';

import { METADATA } from '/src/globals';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';

//////////////////////////

const useStyles = makeStyles( theme => ({
  featureText: {
    padding: '0.8rem 0',
    fontSize: '3em',
  }
}));

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const ClientSideDataFetching = (props) => {

  const classes = useStyles(props);
  const [starsCount, setStarsCount] = useState(0);
  const [ronQuotes, setRonQuotes] = useState([]);
  const [steamTitles, setSteamTitles] = useState([]);

  useEffect(() => {

    // get data from GitHub api
    fetch('https://api.github.com/repos/gatsbyjs/gatsby')
      .then(response => response.json())
      .then(resultData => {
        setStarsCount(resultData.stargazers_count)
      })

    // 2 Random Ron Swanson Quotes
    const ronParams= {
      count: 2,
    };
    fetch(`https://ron-swanson-quotes.herokuapp.com/v2/quotes/${ronParams.count}`)
      .then(response => response.json())
      .then(resultData => {
        setRonQuotes(resultData);
      }).catch(error => {
        console.log(error);
      });

    // Current Steam specials
    const steamParams= {
      storeID: 1, //steam
      sortBy: 'Price',
      onSale: true,
    };
    fetch(`https://www.cheapshark.com/api/1.0/deals?${makeParams(steamParams)}`)
      .then(response => response.json())
      .then(resultData => {
        setSteamTitles(resultData);
      }).catch(error => {
        console.log(error);
      });

  }, [])


  return ( <>

    <Helmet>
      <title>Run-time Data Fetching{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>


    <PageWrapper>
      
      <Typography variant="h1">
        Client-Side Data Fetching
      </Typography>
      <Typography variant="body1">
        Because Gatsby automatically rehydrates the static pages into live react pages when they launch client-side, you can use any usual techniques to fetch run-time data.
      </Typography>
      <ul>
        <Typography variant="body1">
          <li><Link to="https://www.gatsbyjs.com/docs/data-fetching/">Documentation</Link></li>
        </Typography>
      </ul>
      

      <Typography variant="h2">
        Using fetch
      </Typography>
      <Typography variant="body1">
        Runtime Data: Star count for the Gatsby repo:
      </Typography>
      <div>
        <Typography variant="caption" className={classes.featureText}>
          {starsCount}
        </Typography>
      </div>
      <Typography variant="body1">
        Ron Swanson quotes:
      </Typography>
      {ronQuotes.map( (quote, index) => (
        <div key={index}>
          <Typography variant="caption" className={classes.featureText}>
            {quote}
          </Typography>
        </div>
      ))}
      <Typography variant="body1">
        Current Steam sales:
      </Typography>
      <ul>
        {steamTitles.map( (steamTitle, index) => (
          <li key={index}>
            <Typography variant="body1">
              ${steamTitle.salePrice} - <Link to={`https://www.metacritic.com/${steamTitle.metacriticLink}`}>{steamTitle.title}</Link> (Usually ${steamTitle.normalPrice})
            </Typography>
          </li>
        ))}
      </ul>


      <DemoNavigation/>

    </PageWrapper>
    

  </> )

}

ClientSideDataFetching.propTypes = propTypes;
export default ClientSideDataFetching;
