/**
 * CLIENT-SIDE DATA HELPERS
 * Contains functions to support client-side data fetching.
 */



/**
 * Pass in an object and it spits out query params to be used in a URL call.
 * ie. "url.com?" + params
 * ie. "url.com/" + params
 * @param {object} obj 
 * @returns {string}
 */
export const makeParams = (obj) => {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}



// TODO: Incorporate use of this.
/**
 * Parses the JSON returned by a network request\
 * @param  {object} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}



// TODO: Incorporate use of this.
/**
 * Checks if a network request came back fine, and throws an error if not
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}



// TODO: Incorporate use of this.
/**
 * Requests a URL, returning a promise
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);
}
